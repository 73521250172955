import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../Contexts/userContext";
import {
  Box,
  CssBaseline,
  LinearProgress,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebase/firebase";
import { getFirestore } from "firebase/firestore";

import AppContext from "../../Contexts/appContext";
import { LoadUserData } from "../../firebase/loadUserData";
import { useNavigate } from "react-router";
import { config } from "../../Config/config";

import { validateEmail, validatePassword } from "../../Helpers/helpers";
import { NextStepButton } from "src/Config/styling";

/* required initialization of firebase */
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth();

const LoginForm = () => {
  const { login, user } = useContext(UserContext);
  const { setModalInfo } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFormatError, setEmailFormatError] = useState("");
  const [passwordFormatError, setPasswordFormatError] = useState("");

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    }
  }, [user, navigate]);

  useEffect(() => {
    setEmailFormatError("");
  }, [email]);
  useEffect(() => {
    setPasswordFormatError("");
  }, [password]);

  const emailPwLogin = (event: any) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setEmailFormatError("Enter a valid email address");
      return;
    }
    if (!validatePassword(email)) {
      setPasswordFormatError("Minimum length: 8 characters");
      return;
    }

    setShowLoader(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        LoadUserData(user, setModalInfo)
          .then((response: any) => {
            login({
              email: user.email || "",
              displayName: user.displayName || "",
              id: user.uid || "",
              token: response.token,
              orgInfo: response.orgInfo,
            });
            /* update (success message) and subsequently close the login modal */
          })
          .catch((error) => {
            setShowLoader(false);
            setModalInfo({
              open: true,
              message: "Unable to load user data",
            });
          });
      })
      .catch((error) => {
        setShowLoader(false);
        setModalInfo({
          message: prettyError(error.code, error.message),
          open: true,
        });
      });
  };

  // const emailPwSignUp = () => {
  //   const isValidEmail = validateEmail();
  //   const isValidPassword = validatePassword();
  //   if (isValidEmail && isValidPassword) {
  //     /* set login modal while data is retrived  */
  //     setModalInfo({
  //       open: true,
  //       message: "Creating account...",
  //       severity: "info",
  //       hideButton: true,
  //     });
  //     createUserWithEmailAndPassword(auth, email, password)
  //       .then((userCredential) => {
  //         // Signed in
  //         const user = userCredential.user;
  //         login({
  //           email: user.email,
  //           displayName: user.displayName,
  //           id: user.uid,
  //         }).then();

  //         /* update (success message) and subsequently close the login modal */
  //         setModalInfo({
  //           open: true,
  //           message: "Successfully created account.",
  //           severity: "success",
  //           hideButton: true,
  //         });
  //         setTimeout(() => {
  //           setModalInfo({
  //             open: false,
  //           });
  //         }, 2000);
  //       })
  //       .catch((error) => {
  //         setModalInfo({
  //           message: prettyError(error.code, error.message),
  //           open: true,
  //         });
  //       });
  //   }
  // };

  const prettyError = (errorCode: string, errorMessage: string) => {
    if (errorCode) {
      if (errorCode.includes("wrong-password")) {
        return "Incorrect password";
      } else if (errorCode.includes("invalid-email")) {
        return "Invalid email";
      } else if (errorCode.includes("invalid-credential")) {
        return "Email and/or password not found.";
      } else if (errorCode.includes("user-disabled")) {
        return "This account has been disabled";
      } else if (errorCode.includes("email-already-in-use")) {
        return "That email associated with an existing account; please login instead.";
      } else if (errorCode.includes("weak-password")) {
        return "Please choose a more secure password.";
      } else if (errorCode.includes("network-request-failed")) {
        return "Unable to connect to the network. Please check your internet connection and try again.";
      } else {
        return errorMessage;
      }
    }
  };

  const onInputChange = (setter: any) => (e: any) => {
    setter(e.target.value);
  };

  return (
    <Stack style={{ padding: 1, overflow: "scroll" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 3,
          height: "100vh",
          overflow: "auto",
          padding: 10,
        }}
      >
        <Paper
          elevation={4}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            height: 300,
            width: 500,
          }}
        >
          <form onSubmit={emailPwLogin}>
            <Stack>
              <TextField
                error={emailFormatError !== ""}
                id="email"
                label="email"
                value={email}
                size="small"
                margin="dense"
                style={{
                  width: "100%",
                }}
                onChange={onInputChange(setEmail)}
                helperText={emailFormatError}
              />
              <TextField
                error={passwordFormatError !== ""}
                id="passowrd"
                label="password"
                value={password}
                type="password"
                autoComplete="current-password"
                size="small"
                margin="dense"
                style={{
                  width: "100%",
                }}
                onChange={onInputChange(setPassword)}
                helperText={passwordFormatError}
              />
            </Stack>
            <LinearProgress
              sx={{ m: 1, visibility: showLoader ? "visible" : "hidden" }}
              color="primary"
            />

            <NextStepButton type="submit" variant="contained" size="small">
              Login
            </NextStepButton>
            {/* <br />
            <Button size="small" onClick={emailPwSignUp}>
              Sign up
            </Button> */}
          </form>
          <br />
          <br />
          <Typography>
            <i>
              Download the NextStep browerser on{" "}
              <Link
                href={config.chromeExtensionURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Chrome
              </Link>{" "}
              or{" "}
              <Link
                href={config.edgeExtensionUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Microsoft Edge
              </Link>
            </i>
          </Typography>
        </Paper>
      </Box>
    </Stack>
  );
};

export default LoginForm;
