import React, { useContext, useEffect, useReducer } from "react";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import Overview from "../../Pages/Dashboard/Overview/Overview";
import Reporting from "../../Pages/Dashboard/Reporting/Reporting";
import Users from "./Admin/Admin";
import Library from "./Library/Library";
import Teams from "../../Pages/Dashboard/Teams/Teams";

import DashboardMenu from "./sideMenu";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { GetOrgData, GetUsageData } from "../../firebase/getUserData";
import { getAuth, getIdToken } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebase/firebase";
import UserContext from "../../Contexts/userContext";
import DashboardContext from "../../Contexts/dashboardContext";

const app = initializeApp(firebaseConfig);
const auth = getAuth();

const INITIAL_DASHBOARD_STATE = {
  orgMembers: [],
  orgMembersActive: [],
  orgCollections: [],
  orgCollectionsActive: [],
  orgTeams: [],
  orgTeamsActive: [],
  stepHistory: {
    stepLog: [],
    errorLog: [],
  },
};

const dashboardReducer = (state: any, action: any) => {
  switch (action.type) {
    case "setOrgMembers":
      const filteredMembers = action.payload.filter(
        (member: any) => member.status === "active"
      );
      return {
        ...state,
        orgMembers: action.payload,
        orgMembersActive: filteredMembers,
      };
    case "setOrgCollections":
      const filteredCollections = action.payload.filter(
        (collection: any) => collection.status === "active"
      );
      return {
        ...state,
        orgCollections: action.payload,
        orgCollectionsActive: filteredCollections,
      };
    case "setOrgTeams":
      const filteredTeams = action.payload.filter(
        (team: any) => team.status === "active"
      );
      return {
        ...state,
        orgTeams: action.payload,
        orgTeamsActive: filteredTeams,
      };
    case "setStepHistory":
      return {
        ...state,
        stepHistory: action.payload,
      };
    case "setOrgSeqGroups":
      return {
        ...state,
        orgSeqGroups: action.payload,
      };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

export default function Dashboard() {
  const [authToken, setAuthToken] = React.useState<string>("");

  const { user } = useContext(UserContext);
  const { logout } = useContext(UserContext);

  const [dashboardState, dashboardDispatch] = useReducer(
    dashboardReducer,
    INITIAL_DASHBOARD_STATE
  );

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        (async () => {
          const token = await getIdToken(user);
          setAuthToken(token);

          GetOrgData(token, ["members", "collections", "teams"], logout).then(
            (result: any) => {
              dashboardDispatch({
                type: "setOrgMembers",
                payload: result.members || [],
              });
              dashboardDispatch({
                type: "setOrgCollections",
                payload: result.collections || [],
              });
              dashboardDispatch({
                type: "setOrgTeams",
                payload: result.teams || [],
              });
            }
          );
          GetUsageData(token, logout).then((result: any) => {
            dashboardDispatch({
              type: "setStepHistory",
              payload: result.usageHistory,
            });
          });
        })();
      }
    });
  }, []);

  const currentDashboardState = {
    orgMembers: dashboardState.orgMembers || [],
    orgMembersActive: dashboardState.orgMembersActive || [],
    setOrgMembers: (orgMembers: any) =>
      dashboardDispatch({ type: "setOrgMembers", payload: orgMembers }),

    orgCollections: dashboardState.orgCollections || [],
    orgCollectionsActive: dashboardState.orgCollectionsActive || [],
    setOrgCollections: (orgCollections: any) =>
      dashboardDispatch({ type: "setOrgCollections", payload: orgCollections }),

    orgTeams: dashboardState.orgTeams || [],
    orgTeamsActive: dashboardState.orgTeamsActive || [],
    setOrgTeams: (orgTeams: any) =>
      dashboardDispatch({ type: "setOrgTeams", payload: orgTeams }),

    stepHistory: dashboardState.stepHistory || [],
    setStepHistory: (stepHistory: any) =>
      dashboardDispatch({ type: "setStepHistory", payload: stepHistory }),

    orgSeqGroups: dashboardState.orgSeqGroups || [],
    setOrgSeqGroups: (sequenceGroups: any) =>
      dashboardDispatch({ type: "setOrgSeqGroups", payload: sequenceGroups }),
  };

  /* protected routes for admins and managers in users only */
  const AdminRoute = () => {
    return user && user.orgInfo.accountType === "admin" ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    );
  };

  const ManagerRoute = () => {
    return user && user.orgInfo.accountType === "manager" ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    );
  };

  return (
    <DashboardContext.Provider value={currentDashboardState}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <DashboardMenu />
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: "primary.light",
            flexGrow: 3,
            height: "93vh",
            overflow: "auto",
            width: "100%",
          }}
        >
          {" "}
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route element={<AdminRoute />}>
              <Route path="/reporting" element={<Reporting />} />
              <Route path="/admin" element={<Users token={authToken} />} />
              <Route path="/library" element={<Library token={authToken} />} />
              <Route path="/teams" element={<Teams token={authToken} />} />
            </Route>
            <Route element={<ManagerRoute />}>
              <Route path="/reporting" element={<Reporting />} />
              <Route path="/library" element={<Library token={authToken} />} />
              <Route path="/teams" element={<Teams token={authToken} />} />
            </Route>
          </Routes>
        </Box>
      </Box>
    </DashboardContext.Provider>
  );
}
