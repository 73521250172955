import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";

const TabContent = [
  {
    title: "In Your Browser",
    img: require("../../Images/process_step_inserted.png"),
    caption:
      "Users are guided through processes via onscreen instructions that are displayed directly within any web-based application or website",
  },
  {
    title: "Process Library",
    img: require("../../Images/extension_menu_screenshot.png"),
    caption:
      "Build and assign a library of processes to teams, organized by categories such as processes, systems, clients, and more. Users can easily search for a process and view its steps with just a click.",
  },
  {
    title: "Step-by-step",
    img: require("../../Images/extension_steplist_screenshot.png"),
    caption:
      "Users can either start the process from the beginning or jump directly to any specific step.",
  },
];

export default function OverviewTabs() {
  const tabActions = React.useRef<any>();

  /* tab controls */
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [userInteracted, setUserInteracted] = React.useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setUserInteracted(true);
    setActiveTab(newValue);
  };
  useEffect(() => {
    /* change tabs every 5s until the user interacts with the page */
    const interval = setInterval(() => {
      if (!userInteracted) {
        if (activeTab === TabContent.length - 1) {
          setActiveTab(0);
        } else {
          setActiveTab(activeTab + 1);
        }
      }
    }, 5000);

    /* cleanup */
    return () => clearInterval(interval);
  }, [activeTab]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      key: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{
          height: "92%",
          overflow: "scroll",
          backgroundColor: "white",
        }}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        key={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1, height: "100%", alignContent: "center" }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box m={1} height={"100%"} overflow={"hidden"}>
      <Tabs
        action={tabActions}
        value={activeTab}
        onChange={handleChange}
        aria-label="login tabs"
        centered
        variant="fullWidth"
        textColor="inherit"
        indicatorColor="primary"
        TabIndicatorProps={{
          sx: {
            height: 4,
            boxShadow: (theme) =>
              `0px 0px 20px -5px ${theme.palette.primary.main}`,
          },
        }}
      >
        {TabContent.map((tab: any, index) => (
          <Tab
            label={tab.title}
            {...a11yProps(index)}
            sx={{
              mt: 1,
              color: "white",
              bgcolor: "#777",
              borderRadius: "20px 20px 0 0",

              boxShadow:
                index === activeTab
                  ? (theme) => `0px 0px 10px -5px ${theme.palette.primary.main}`
                  : "",
              height: index === activeTab ? 4 : 2,
            }}
          />
        ))}
      </Tabs>
      {TabContent.map((tab: any, index) => (
        <CustomTabPanel value={activeTab} index={index} key={index}>
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            alignItems="stretch"
          >
            <Grid item xs={6} alignContent="center">
              <Box
                component="img"
                sx={{
                  m: {
                    xs: 3,
                    md: 0,
                  },
                  maxHeight: {
                    xs: "80%",
                    md: "88%",
                  },
                  maxWidth: {
                    xs: "80%",
                    md: "88%",
                  },
                  boxShadow: (theme) =>
                    `0px 0px 20px -5px ${theme.palette.primary.dark}`,
                }}
                alt={tab.title}
                src={tab.img}
              />
            </Grid>
            <Grid item xs={6} alignContent="center">
              <Typography
                variant="h6"
                color="primary.dark"
                textAlign={{ xs: "center", md: "left" }}
                sx={{
                  mb: {
                    xs: 5,
                    md: 0,
                  },
                }}
              >
                {tab.caption}
              </Typography>{" "}
            </Grid>
          </Grid>
        </CustomTabPanel>
      ))}
    </Box>
  );
}
