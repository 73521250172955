import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

export default function StepLogTable(data: any) {
  // const { stepHistory } = useContext(DashboardContext);

  let sortedHistory = data.stepHistory.sort(function (x: any, y: any) {
    return Date.parse(y.timestamp) - Date.parse(x.timestamp);
  });

  return (
    <React.Fragment>
      <Typography fontWeight="bold" color="primary.dark" variant="h5">
        Usage Log{" "}
      </Typography>
      <Stack
        sx={{
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "secondary.dark" }}>User</TableCell>
              <TableCell sx={{ color: "secondary.dark" }}>Timestamp</TableCell>
              <TableCell sx={{ color: "secondary.dark" }}>Sequence</TableCell>
              <TableCell sx={{ color: "secondary.dark" }}>Step</TableCell>
            </TableRow>
          </TableHead>
          {sortedHistory.length > 0 ? (
            <TableBody>
              {sortedHistory.map((row: any) => (
                <TableRow key={row.email + row.timestamp + Math.random()}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.timestamp}</TableCell>
                  <TableCell>{row.sequence}</TableCell>
                  <TableCell>{row.title || row.stepTitle}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                {/* column for each header */}
                {[...Array(4)].map((c, ic) => (
                  <TableCell key={ic}>
                    {/* n = 10 rows */}
                    {[...Array(10)].map((r, ir) => (
                      <Skeleton
                        key={"column:" + ic + "row:" + ir}
                        variant="text"
                        sx={{ width: "100%", fontSize: "1rem" }}
                      />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Stack>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more activity
      </Link> */}
    </React.Fragment>
  );
}
