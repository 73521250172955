import React, { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TeamCard from "./teamCard";
import DashboardContext from "../../../Contexts/dashboardContext";
import TeamMemberModal from "../modals/teamMemberModal";
import {
  Typography,
  ListItemText,
  ListItemButton,
  Autocomplete,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
import { NextStepList, SearchField, client } from "../../../Config/styling";

import SearchIcon from "@mui/icons-material/Search";

export default function Teams(props: { token: string }) {
  const { orgCollectionsActive, orgTeamsActive } = useContext(DashboardContext);

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [teamOptions, setTeamOptions] = React.useState<any[]>(orgTeamsActive);
  React.useEffect(() => {
    setTeamOptions(orgTeamsActive);
  }, [orgTeamsActive]);

  const [selectedTeamId, setSelectedTeamId] = React.useState<any>("");
  const [selectedTeam, setSelectedTeam] = React.useState<any>(
    orgTeamsActive[0]
  );
  React.useEffect(() => {
    let tempSelectedTeam = orgTeamsActive.find(
      (team: any) => team.id === selectedTeamId
    );
    setSelectedTeam(tempSelectedTeam);
  }, [selectedTeamId, orgTeamsActive]);

  const [member, setMember] = React.useState<any>({});
  const [action, setAction] = React.useState<string>("");

  /* collection selector controls */
  const [selectedCollection, setSelectedCollection] = React.useState<any>(null);
  const handleCollectionChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: any
  ) => {
    if (reason === "clear") {
      setSelectedCollection(null);
      setTeamOptions(orgTeamsActive);
      setSelectedTeamId("");
    } else {
      setSelectedCollection(value);

      /* filter and set teams */
      const filteredTeams = orgTeamsActive.filter((team: any) =>
        team.collections.includes(value.id)
      );
      setTeamOptions(filteredTeams);
      setSelectedTeamId("");
    }
  };

  /* team selector list controls */
  const handleListItemClick = (
    // event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    team: any
  ) => {
    setSelectedTeamId(team.id);
  };

  /* filter member list based on search */
  React.useState<any[]>(teamOptions);

  const handleSearchChange = (e: any) => {
    /* handle clear case */
    if (e.target.value === "") {
      /* check of collection is set */
      if (selectedCollection) {
        const filteredTeams = orgTeamsActive.filter((team: any) =>
          team.collections.includes(selectedCollection.id)
        );
        setTeamOptions(filteredTeams);
      } else {
        setTeamOptions(orgTeamsActive);
      }
    } else {
      const tempTeamList = [...teamOptions];

      let filteredTeamList = tempTeamList.filter((team: any) =>
        (team.display_name || team.id)
          .toLowerCase()
          .trim()
          .includes(e.target.value.toLowerCase().trim())
      );
      setTeamOptions(filteredTeamList);
    }
  };

  return (
    <Box>
      <CssBaseline />
      <Grid container spacing={4} p={4} height={"80vh"}>
        {" "}
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            id="collectionSelect"
            options={orgCollectionsActive}
            value={selectedCollection}
            slotProps={client.Autocomplete_SlotProps}
            getOptionLabel={(option: any) =>
              option.display_name ? option.display_name : option.id
            }
            isOptionEqualToValue={(option, value) =>
              option.display_name
                ? option.display_name === value.display_name
                : option.id === value.id || false
            }
            onChange={handleCollectionChange}
            renderInput={(collectionParams) => (
              <TextField
                {...collectionParams}
                label="Collection Filter"
                color="secondary"
              />
            )}
          />
          <Paper
            elevation={0}
            sx={{
              border: "lightgray solid 1px",
              mt: 4,
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "410px",
            }}
          >
            <Typography variant="h6" pb={2} align="left" color={"secondary"}>
              Select a team
            </Typography>

            <SearchField
              id="outlined-search"
              label="Search for member"
              type="search"
              size="small"
              sx={{
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={handleSearchChange}
            />
            <NextStepList component="nav" aria-label="team search">
              {teamOptions.map((team: any) => (
                <ListItemButton
                  key={team.id}
                  selected={selectedTeam === team}
                  onClick={() => handleListItemClick(team)}
                >
                  <ListItemText primary={team.display_name || team.id} />
                </ListItemButton>
              ))}
              {teamOptions.length === 0 && (
                <i>No teams found for the current search criteria</i>
              )}
            </NextStepList>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <TeamCard
            team={selectedTeam}
            setModalOpen={setModalOpen}
            setMember={setMember}
            setAction={setAction}
          />
        </Grid>
      </Grid>

      {modalOpen && (
        <TeamMemberModal
          open={modalOpen}
          setOpen={setModalOpen}
          member={member}
          team={selectedTeam}
          token={props.token}
          action={action}
        />
      )}
    </Box>
  );
}
