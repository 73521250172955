import React, { useContext, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import SequenceGroup from "./sequenceGroup";

import {
  Grid,
  Paper,
  Typography,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CollectionCard from "./collectionCard";
import DashboardContext from "../../../Contexts/dashboardContext";
import EditItemModal from "../modals/editItemModal";
import AddItemModal from "../modals/addItemModal";
import { GetOrgData } from "../../../firebase/getUserData";
import UserContext from "../../../Contexts/userContext";
import { NextStepList, SearchField } from "../../../Config/styling";

export default function Library(props: { token: string }) {
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);

  /* collection filter controls */
  const { orgCollectionsActive, setOrgSeqGroups, orgSeqGroups } =
    useContext(DashboardContext);

  const { logout } = useContext(UserContext);

  /* need to reset the underline of the active tab manually when the side menu is open */
  // const tabActions = React.useRef<any>();

  useEffect(() => {
    /* populate org sequence info on page load */
    if (!orgSeqGroups || orgSeqGroups.length < 1) {
      GetOrgData(props.token, ["sequenceList"], logout).then((result: any) => {
        setOrgSeqGroups(result.sequenceGroups);
      });
    }

    // if (!document.getElementById("tabsRow")) return;
    // const resizeObserver = new ResizeObserver(() => {
    //   if (tabActions.current) {
    //     tabActions.current.updateIndicator();
    //   }
    // });
    // resizeObserver.observe(document.getElementById("tabsRow")!);
    // return () => resizeObserver.disconnect(); // clean up
  }, []);

  const [collectionOptions, setCollectionOptions] =
    React.useState<any[]>(orgCollectionsActive);

  const [collectionOptionsFiltered, setTeamOptionsFiltered] =
    React.useState<any[]>(collectionOptions);

  const [selectedCollection, setSelectedCollection] = React.useState<any>(null);
  useEffect(() => {
    setCollectionOptions(orgCollectionsActive);
    setTeamOptionsFiltered(orgCollectionsActive);
    /* if a collection is selected, need to update it so that references to sequence groups are reflected */
    if (selectedCollection) {
      setSelectedCollection(
        orgCollectionsActive.find(
          (collection: any) => collection.id === selectedCollection.id
        )
      );
    }
  }, [orgCollectionsActive]);

  const handleSearchChange = (e: any) => {
    const tempTeamList = [...collectionOptions];

    let filteredTeamList = tempTeamList.filter((collection: any) =>
      (collection.display_name || collection.id)
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase().trim())
    );
    setTeamOptionsFiltered(filteredTeamList);
  };

  const [collectionSeqGroups, setCollectionSeqGroups] = React.useState<any>([]);

  const handleListItemClick = (collection: any) => {
    setSelectedCollection(collection);

    const collectionSeqGroupArr = orgSeqGroups.filter((seqGroup: any) =>
      (collection.sequence_groups || []).includes(Object.keys(seqGroup)[0])
    );
    setCollectionSeqGroups(collectionSeqGroupArr);

    if (!orgSeqGroups || orgSeqGroups.length < 1) {
      GetOrgData(props.token, ["sequenceList"], logout).then((result: any) => {
        setOrgSeqGroups(result.sequenceGroups);
      });
    }
  };

  const [sequenceGroup, setSequenceGroup] = React.useState<any>(null);
  const [sequenceGroupDocId, setSequenceGroupDocId] = React.useState<any>(null);
  useEffect(() => {
    if (orgSeqGroups && sequenceGroup) {
      const newSequenceGroupDocId = Object.keys(
        orgSeqGroups.find(
          (group: any) =>
            (Object.values(group)[0] as any).id === sequenceGroup.id
        )
      )[0];
      setSequenceGroupDocId(newSequenceGroupDocId);
    }
  }, [orgSeqGroups, sequenceGroup]);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <CssBaseline />
      <Grid container spacing={4} p={4} id={"lib grid"}>
        {" "}
        <Grid item xs={4}>
          <Paper
            elevation={0}
            sx={{
              border: "lightgray solid 1px",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "450px",
            }}
          >
            <Typography variant="h6" pb={2} align="left" color={"secondary"}>
              Select a collection
            </Typography>
            <SearchField
              id="outlined-search"
              label="Search for collection"
              type="search"
              size="small"
              style={{
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={handleSearchChange}
            />

            <NextStepList component="nav" aria-label="team search">
              {collectionOptionsFiltered.map((team: any) => (
                <ListItemButton
                  key={team.id}
                  selected={selectedCollection === team}
                  onClick={() => handleListItemClick(team)}
                >
                  <ListItemText primary={team.display_name || team.id} />
                </ListItemButton>
              ))}
              {collectionOptionsFiltered.length === 0 && (
                <i>No teams found for the current search criteria</i>
              )}
            </NextStepList>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <CollectionCard
            collection={selectedCollection}
            seqGroups={collectionSeqGroups}
            setEditModalOpen={setEditModalOpen}
            setAddModalOpen={setAddModalOpen}
            setSequenceGroup={setSequenceGroup}
          />
        </Grid>
        <Grid item xs={12}>
          <SequenceGroup token={props.token} seqGroups={collectionSeqGroups} />
        </Grid>
      </Grid>
      <br />
      <br />
      <br />

      {editModalOpen && (
        <EditItemModal
          open={editModalOpen}
          setOpen={
            setEditModalOpen as React.Dispatch<React.SetStateAction<boolean>>
          }
          token={props.token}
          itemType={"sequence-group"}
          item={{
            id: sequenceGroupDocId,
            displayName: sequenceGroup.displayName,
          }}
          parentItem={selectedCollection}
        />
      )}
      {addModalOpen && (
        <AddItemModal
          open={addModalOpen}
          setOpen={
            setAddModalOpen as React.Dispatch<React.SetStateAction<boolean>>
          }
          token={props.token}
          itemType={"sequence-group"}
          item={{ displayName: "" }}
          parentItem={selectedCollection}
        />
      )}
    </Box>
  );
}

