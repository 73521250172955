import React, { useEffect } from "react";

import Container from "@mui/material/Container";

import {
  Autocomplete,
  Box,
  Fab,
  Grid,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";


import StepList from "./stepList";
import SequenceModal from "../modals/sequenceModal";
import { client } from "../../../Config/styling";
import { sequence, step } from "./types";

export default function Sequence(props: {
  action: string;
  token: string;
  stepArray: step[];
  sequenceInfo: sequence;
  seqGroupDocId: string;
  groupSequences: sequence[];
}) {
  const [seqModalOpen, setSeqModalOpen] = React.useState<boolean>(false);

  const [stepArray, setStepArray] = React.useState<step[]>(props.stepArray);

  const updateStep = (index: number, step: step) => {
    const newStepArray = JSON.parse(JSON.stringify(stepArray));
    newStepArray[index] = { ...newStepArray[index], ...step };
    setStepArray(newStepArray);
  };

  const [sequenceInfo, setSequenceInfo] = React.useState<any>(
    props.sequenceInfo
  );
  // const [selectedStepIndex, setSelectedStepIndex] = React.useState<any>(null);

  const [updateTrigger, setUpdateTrigger] = React.useState<boolean>(false);
  const [submitReady, setSubmitReady] = React.useState<boolean>(false);
  useEffect(() => {
    setSubmitReady(false);
  }, [stepArray]);

  useEffect(() => {
    setStepArray(props.stepArray);
    setSequenceInfo({ ...sequenceInfo, ...props.sequenceInfo });
  }, [props.sequenceInfo]);

  /* when seqGroupDocId is not set is updated, need to wipe seq and stepArray */
  useEffect(() => {
    if (!props.seqGroupDocId) {
      setStepArray([]);
    }
  }, [props.seqGroupDocId]);

  // const [isPasteEnabled, setIsPasteEnabled] = React.useState<boolean>(false);
  // useEffect(() => {
  //   /* set a listener for when the page focus changes */
  //   var handler = function (event: any) {
  //     if (document.hasFocus()) {
  //       CheckClipboard(setIsPasteEnabled);
  //     }
  //   };
  //   document.addEventListener("focus", handler, true);

  //   /* also check the clip status on first load */
  //   CheckClipboard(setIsPasteEnabled);
  // }, []);

  // /* paste in the config info from the step builder page in the extension */
  // const pasteConfig = () => {
  //   navigator.clipboard.readText().then((clipText) => {
  //     try {
  //       const configInfo = JSON.parse(clipText);

  //       updateStep(selectedStepIndex, configInfo as builder_step);
  //     } catch {
  //       alert("Unable to paste step information");
  //     }
  //   });
  // };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    status: string
  ) => {
    setSequenceInfo({
      ...sequenceInfo,
      ["status"]: status,
    });
  };

  const handleInputChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = e.target.id;

    const tempSequenceInfo = JSON.parse(JSON.stringify(sequenceInfo));

    tempSequenceInfo[property] = e.target.value;
    setSequenceInfo(tempSequenceInfo);
  };

  const handleChainChange = (e: any, value: any) => {
    setSequenceInfo({ ...sequenceInfo, chainedSequences: value });
  };

  const [seqInfo, setSeqInfo] = React.useState<any>({});

  const handleSubmit = () => {
    let seqObj = JSON.parse(JSON.stringify(sequenceInfo));
    seqObj.stepList = stepArray;

    const seqInfo = {
      seqGroup: props.seqGroupDocId,
      seqId: props.sequenceInfo.id,
      seqObj: seqObj,
    };
    setSeqInfo(seqInfo);
    setSeqModalOpen(true);
  };

  return (
    <Stack sx={{ display: "flex" }}>
      <Grid container alignItems="top" p={1} spacing={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            color="secondary"
            key="description"
            id="description"
            label="Description"
            value={sequenceInfo.description || ""}
            type="search"
            variant="standard"
            style={{
              padding: 10,
            }}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            color="secondary"
            key="initialUrl"
            id="initialUrl"
            label="Initial Url"
            value={sequenceInfo.initialUrl || ""}
            type="search"
            variant="standard"
            style={{
              padding: 10,
            }}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={4} alignContent={"center"}>
          <ToggleButtonGroup
            color="secondary"
            value={sequenceInfo.status || "active"}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="disabled">Disabled</ToggleButton>
            <ToggleButton value="draft">Draft</ToggleButton>
            <ToggleButton value="active">Active</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {stepArray && stepArray.length > 0 && (
        <StepList
          stepArray={stepArray}
          setStepArray={setStepArray}
          updateStep={updateStep}
          // selectedStepIndex={selectedStepIndex}
          // setSelectedStepIndex={setSelectedStepIndex}
          updateTrigger={updateTrigger}
        />
      )}
      {!stepArray ||
        (stepArray.length === 0 && (
          <Container
            maxWidth="lg"
            sx={{
              mt: 2,
              flexGrow: 1,
              bgcolor: "white",
              width: "100%",
              height: "900px",
            }}
          >
            <Stack
              direction="row"
              sx={{ display: "flex", height: "98%", py: 2 }}
            >
              <Box width={"45%"}>
                <Typography variant="h4">Step List</Typography>
                {[...Array(4)].map((c, i) => (
                  <Skeleton
                    key={i}
                    variant="rounded"
                    width={"100%"}
                    height={150}
                    sx={{ m: 2 }}
                  />
                ))}
              </Box>
              <Box width={"5%"}></Box>
              <Box width={"60%"}>
                <Typography variant="h4">Step Details</Typography>
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={"93%"}
                  sx={{ m: 2 }}
                />
              </Box>
            </Stack>
          </Container>
        ))}

      <Autocomplete
        sx={{ p: 2 }}
        multiple
        limitTags={5}
        id="chained-processes"
        slotProps={client.Autocomplete_SlotProps}
        options={(props.groupSequences || []).filter(
          (seq: any) => seq.id !== sequenceInfo.id
        )}
        value={sequenceInfo.chainedSequences || []}
        onChange={handleChainChange}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id || false
        }
        getOptionLabel={(option: any) => option.name}
        getOptionKey={(option: any) => option.id}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            variant="standard"
            label="Chained Processes"
            placeholder="Available Options"
          />
        )}
      />

      {seqModalOpen && (
        <SequenceModal
          token={props.token}
          open={seqModalOpen}
          setOpen={setSeqModalOpen}
          action={props.action}
          seqInfo={seqInfo}
        />
      )}
      {/* <Fab
        disabled={!isPasteEnabled}
        variant="extended"
        sx={{
          position: "fixed",
          pl: 10,
          pr: 10,
          bottom: "30px",
          right: "280px",
        }}
        color="secondary"
        onClick={pasteConfig}
      >
        Paste from Clipboard
      </Fab> */}

      <Fab
        variant="extended"
        sx={{
          position: "fixed",
          pl: 10,
          pr: 10,
          bottom: "30px",
          right: "30px",
        }}
        color="secondary"
        onClick={
          !submitReady
            ? (e) => {
                setUpdateTrigger(!updateTrigger);
                setTimeout(() => {
                  setSubmitReady(true);
                }, 1);
              }
            : (e) => {
                e.preventDefault();
                handleSubmit();
              }
        }
      >
        {!submitReady ? "Save" : "Submit"}
      </Fab>
    </Stack>
  );
}

// function CheckClipboard(setIsClipStep: any) {
//   /* check if the contents of the clipboard conform to the 'step' data formant and set the state accordingly */
//   try {
//     /* throw an error if the page is not in focus */
//     if (!document.hasFocus()) {
//       throw Error;
//     }

//     navigator.clipboard.readText().then((clipText) => {
//       /* also throw an error if the contents of the clipboard are not valid JSON */
//       try {
//         JSON.parse(clipText);

//         const subjectArr = Object.keys(JSON.parse(clipText));
//         const testArr = ["anchorElement", "title", "description", "dialogBox"];

//         let checker = (arr: string[], target: string[]) =>
//           target.every((v: string) => arr.includes(v));

//         setIsClipStep(checker(subjectArr, testArr));
//       } catch {
//         setIsClipStep(false);
//       }
//     });
//   } catch {
//     setIsClipStep(false);
//   }
// }
