import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import UserContext from "../Contexts/userContext";
import { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Backdrop from "@mui/material/Backdrop";

import Drawer from "@mui/material/Drawer";

import LoginForm from "../Pages/Login/Login";

const pages = [
  { title: "About", route: "/about" },
  { title: "Contact", route: "/contact" },
];

export default function MenuAppBar(props: { isMobile: boolean }) {
  let navigate = useNavigate();
  const { user, logout } = useContext(UserContext);
  const handleOpen = () => setUserMenuOpen(true);
  const handleClose = () => setUserMenuOpen(false);

  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const [actions, setActions] = React.useState<any[]>([]);

  useEffect(() => {
    if (user) {
      setLoginOpen(false);
      setActions([
        {
          icon: <DashboardIcon />,
          name: "Dashboard",
          action: () => {
            handleClose();
            navigate("/dashboard");
          },
        },
        {
          icon: <LogoutIcon />,
          name: "Logout",
          action: () => {
            logout(false);
            handleClose();
          },
        },
      ]);
    } else
      setActions([
        {
          icon: <LoginIcon />,
          name: "Login",
          action: () => {
            setLoginOpen(true);
            // navigate("/login");
            handleClose();
          },
        },
      ]);
  }, [user]);

  const [loginOpen, setLoginOpen] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      event.preventDefault();
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setLoginOpen(open);
      handleClose();
    };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={3}
        position="fixed"
        sx={{ bgcolor: "primary.light", zIndex: 5000 }}
      >
        <Container>
          <Toolbar disableGutters>
            <img
              style={{ height: props.isMobile ? "40px" : "50px" }}
              src={require("../Images/Full_Icon_Transparent.png")}
            />
            <Typography
              variant={props.isMobile ? "h5" : "h4"}
              noWrap
              component="a"
              href="/"
              color="primary"
              sx={{
                mt: 0.4,
                ml: 1,
                mr: 2,
                display: "flex",
                fontWeight: 800,
                textDecoration: "none",
              }}
            >
              NextStep
            </Typography>
            <Box
              sx={{
                justifyContent: "right",
                flexGrow: 1,
                display: "flex",
                marginInlineEnd: props.isMobile ? 0 : 12,
              }}
            >
              {pages.map((page) => (
                <Button
                  color="primary"
                  key={page.title}
                  onClick={() => {
                    navigate(page.route);
                    handleClose();
                  }}
                  size={props.isMobile ? "small" : "large"}
                  sx={{
                    my: "auto",
                    display: "block",
                    fontWeight: "bold",
                    pr: 1,
                  }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>

            <Backdrop open={userMenuOpen} sx={{ bgColor: "#ffffff" }} />
            {!props.isMobile && ( // dashboard isn't optimized for mobile use, so hide if screen is too small
              <SpeedDial
                ariaLabel="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                sx={{ position: "absolute", top: 5, right: 5 }}
                icon={<AccountCircle />}
                direction="down"
                onClose={handleClose}
                onOpen={handleOpen}
                open={userMenuOpen}
                FabProps={{
                  sx: { color: user ? "primary.main" : "divider" },
                  style: { backgroundColor: "white" },
                }}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={action.action}
                    FabProps={{
                      sx: { color: "primary.main" },
                    }}
                  />
                ))}
              </SpeedDial>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <React.Fragment key={"loginDrawer"}>
        <Drawer
          anchor={"right"}
          open={loginOpen}
          onClose={toggleDrawer(false)}
          sx={{ width: "800px" }}
        >
          <Box>
            <LoginForm />
          </Box>
        </Drawer>
      </React.Fragment>
    </Box>
  );
}
